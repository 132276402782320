import React, { useCallback, useContext, useEffect, useState } from "react";
import { Script } from "gatsby";
import axios from "axios";
import { sha512 } from "js-sha512";

// Public Var
import { dev } from "../../var/development.var";
import { staging } from "../../var/staging.var";
import { production } from "../../var/production.var";

// Global Context
import GlobalContext from "../../contexts/GlobalContext";

// Custom Style
import "../../assets/scss/bootstrap/bootstrap.scss"
import "../../assets/scss/custom-style.scss"
import "../../assets/scss/spacing.scss"
import { IBreakPoint } from "../../@types/global";

// Keen Slider
import 'keen-slider/keen-slider.min.css';

// React Toastify
import 'react-toastify/dist/ReactToastify.min.css';

// Component Style
import "../../components/scss/article.scss";
import "../../components/scss/contact.scss";
import "../../components/scss/client.scss";
import "../../components/scss/testimony.scss";
import "../../components/scss/service.scss";

interface LayoutProps {
    children: any
}

const delay = .5;
 
const Layout: React.FC<LayoutProps> = ({ children }) => {

    const gContext = useContext(GlobalContext);
    const [showScript, setScript] = useState(false);

    const updateDimensions = useCallback(() => {
        const outer = window.outerWidth;
        const breakpoint: IBreakPoint = {
            mobile: !!(outer <= 576),
            tablet: !!(outer > 577 && outer <= 992),
            desktop: !!(outer > 993 && outer <= 1200),
            desktopLarge: !!(outer > 1201 && outer <= 1440),
            twoK: !!(outer > 1441 && outer <= 2560),
            fourK: !!(outer > 2561)
        };
        gContext?.setBreakpoint(breakpoint);
    }, []);

    const generateSignature = (url: string | undefined) => {
        // console.log(process.env.baseUrlSHA);
        // console.log(process.env.apiKey);
        
        const rawSignature = (process.env.baseUrlSHA + '/' + url + process.env.apiKey)
          .trim()
          .replace(/\s/g, '%20')
          .replace('//', '/');
    
        if (process.env.NODE_ENV === 'development' && !process.env.STAGING) {
          console.group();
          console.log(rawSignature);
          console.log(sha512(rawSignature));
          console.groupEnd();
        }
        return sha512(rawSignature);
    };

    useEffect(() => {
        const currentUrl = window.location.href.split('/');

        // Setup Environment Variables
        const tempEnv: {
            production: boolean
            staging: boolean
            dev: boolean
        } = {
            production: process.env.NODE_ENV === 'production',
            staging: process.env.NODE_ENV === 'staging',
            dev: process.env.NODE_ENV === 'development'
        }
        gContext?.setPublicVariable(tempEnv.dev ? dev : (
            tempEnv.staging ? staging : production
        ));

        // Debugging Purpose
        console.log(process.env.NODE_ENV);
        // console.log(gContext?.environment);
        // console.log(currentUrl);
        // console.log(currentUrl[3]);

        gContext?.setHeader({onHome: !currentUrl[3]});
        
        if (currentUrl[3] === 'service') {
            gContext?.setFooter({style: 'style2'});
        } else if (currentUrl[3] === 'services' ||
                   currentUrl[3] === 'about' ||
                   currentUrl[3] === 'client' ||
                   currentUrl[3] === 'news') {
            gContext?.setFooter({style: 'style1'});
        } else {
            gContext?.setFooter({style: 'default'});
        }

        setScript(false);
        let timer1 = setTimeout(() => setScript(true), delay * 1000);
        return () => {
          clearTimeout(timer1);
        };

    }, [window.location.href]);

    useEffect(() => {
        updateDimensions();
        let timer1 = setTimeout(() => setScript(true), delay * 1000);
        return () => {
          clearTimeout(timer1);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return window.removeEventListener('resize', updateDimensions);
    }, [updateDimensions]);

    // Add a request interceptor
    if (process.env.baseUrlSHA) {
        axios.interceptors.request.use(function (request) {
            // console.log(window.location.href);
            // console.log(request.url);
            // Do something before request is sent
            request.headers['X-Signature'] = generateSignature(request?.url);
            request.headers['X-Platform'] = 'web_client';
            request.url =  request.url && window.location.href.includes('https://www') ?
                request.url.replace('https://spcgroup.co.id', 'https://www.spcgroup.co.id') :
                request.url
            return request;
        }, function (error) {
            // Do something with request error
            return Promise.reject(error);
        });
    
        // Add a response interceptor
        axios.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
        });
    }

    return (<>
        {children}

        {showScript && <Script>feather.replace()</Script>}
    </>);
}
 
export default Layout;