import React from "react";

import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

import { GlobalProvider } from "./src/contexts/GlobalContext";
import Layout from "./src/components/layouts/Layout";

export const wrapPageElement = ({ element, props }) => {
    // props provide same data to Layout as Page element will get
    // including location, data, etc - you don't need to pass it
    return <GlobalProvider>
        <Layout {...props}>{element}</Layout>
    </GlobalProvider>;
};

// export const wrapRootElement = () => (
//     <GlobalProvider></GlobalProvider>
// );