import { IEnvironment } from "../interfaces/environment.i";

export const dev: IEnvironment = {
  production: false,
  staging: false,
  internal: {
    mailto: 'work.marespradana@gmail.com',
    wato: '6289639400204',
    faWato: '+62 896 3940 0204',
    baMandiri: 1270010258919,
    faMandiri: '127 0010 2589 19',
    bnMandiri: 'SINERGI TEKNO CIPTA',
  }
};
